export default [
    {
        title: 'Home',
        route: 'home',
        icon: 'HomeIcon',
    },
    {
        title: 'Perfiles',
        route: 'admin-profiles',
        icon: 'UsersIcon',
    },
    {
        title: 'Productos',
        route: 'admin-products',
        icon: 'BoxIcon',
    },
    {
        title: 'Ordenes',
        route: 'admin-orders',
        icon: 'DollarSignIcon',
    },
    {
        title: 'Noticias',
        route: 'admin-news',
        icon: 'FileTextIcon',
    },
    {
        title: 'Administración',
        route: 'admin-users',
        icon: 'UsersIcon',
    },

]
